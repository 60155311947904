import React from 'react';

import css from '../css/components/Social.module.css';

class Social extends React.Component {
  render() {
    return (
      <ul className={css.social}>
        <li>
          <Instagram />
        </li>
        <li>
          <Facebook />
        </li>
        <li>
          <Email />
        </li>
      </ul>
    );
  }
}

const SocialLink = ({ url, ...props }) => (
  <a href={url} target="_blank" rel="noopener noreferrer" className={css.socialLink}>
    {props.children}
  </a>
);

const InstagramLogo = props => (
  <svg
    width={24}
    height={24}
    fill="none"
    stroke="currentColor"
    strokeWidth={2}
    strokeLinecap="round"
    strokeLinejoin="round"
    className={css.instagram}
    {...props}
  >
    <rect x={2} y={2} width={20} height={20} rx={5} ry={5} />
    <path d="M16 11.37A4 4 0 1 1 12.63 8 4 4 0 0 1 16 11.37zM17.5 6.5h0" />
  </svg>
);

const FacebookLogo = props => (
  <svg
    width={24}
    height={24}
    fill="none"
    stroke="currentColor"
    strokeWidth={2}
    strokeLinecap="round"
    strokeLinejoin="round"
    className={css.facebook}
    {...props}
  >
    <path d="M18 2h-3a5 5 0 0 0-5 5v3H7v4h3v8h4v-8h3l1-4h-4V7a1 1 0 0 1 1-1h3z" />
  </svg>
);

const EmailLogo = props => (
  <svg
    width={24}
    height={24}
    fill="none"
    stroke="currentColor"
    strokeWidth={2}
    strokeLinecap="round"
    strokeLinejoin="round"
    className={css.email}
    {...props}
  >
    <path d="M4 4h16c1.1 0 2 .9 2 2v12c0 1.1-.9 2-2 2H4c-1.1 0-2-.9-2-2V6c0-1.1.9-2 2-2z" />
    <path d="M22 6l-10 7L2 6" />
  </svg>
);

export const Facebook = props => (
  <SocialLink url="https://www.facebook.com/isisjewelery/">
    <FacebookLogo {...props} />
  </SocialLink>
);

export const Instagram = props => (
  <SocialLink url="https://instagram.com/isis_jewellery/">
    <InstagramLogo {...props} />
  </SocialLink>
);

export const Email = props => (
  <SocialLink url="mailto:alice@lisis.cz">
    <EmailLogo {...props} />
  </SocialLink>
);

export default Social;
