import React from 'react';

const fill = '#b3b3b4';;

const External = props => (
  <svg
    height={props.height}
    width={props.width}
    viewBox="0 0 16 16"
    xmlns="http://www.w3.org/2000/svg"
  >
    <title>launch</title>
    <g fill={fill} stroke={fill} strokeWidth="1">
      <line fill="none" strokeLinecap="round" x1="15.5" x2="7.5" y1="0.5" y2="8.5" />
      <polyline fill="none" points="8.5,0.5 15.5,0.5 15.5,7.5 " strokeLinecap="round" />
      <path
        d="M12.5,9.5v5 c0,0.552-0.448,1-1,1h-10c-0.552,0-1-0.448-1-1v-10c0-0.552,0.448-1,1-1h5"
        fill="none"
        stroke={fill}
        strokeLinecap="round"
      />
    </g>
  </svg>
);

export default External;
