import React from 'react';
import css from '../css/components/LogoSmall.module.css';

const LogoSmall = props => (
  <div className={css.logo}>
    <svg viewBox="0 0 512 512" {...props}>
      <title>ISIS Jewellery</title>
      <g fill="#000" fillRule="nonzero">
        <path d="M251.3 495.98c-.255 0-.51-.085-.68-.34L131.95 334.123c-.256-.34-.17-.935.17-1.19.34-.255.935-.17 1.19.17L251.982 494.62c.255.34.17.935-.17 1.19-.17.085-.34.17-.51.17z" />
        <path d="M132.63 334.463c-.425 0-.766-.34-.85-.765l-17.27-159.817c-.085-.425.256-.85.766-.935.51-.085.85.255.936.765l17.269 159.817c.085.425-.255.85-.766.935h-.085z" />
        <path d="M115.276 174.562a.92.92 0 0 1-.595-.255.91.91 0 0 1-.086-1.19l141.3-157.267c.34-.34.85-.34 1.19-.085.34.34.34.85.085 1.19L115.872 174.222c-.086.255-.34.34-.596.34z" />
        <path d="M189.711 161.895c-.085 0-.255 0-.34-.085-.426-.17-.596-.68-.426-1.105l66.864-144.6c.17-.425.68-.595 1.106-.425.426.17.596.68.426 1.105l-66.864 144.6c-.17.34-.426.51-.766.51z" />
        <path d="M206.895 217.746c-.34 0-.68-.255-.85-.595l-17.185-55.85a.755.755 0 0 1 .596-1.02.755.755 0 0 1 1.02.594l17.185 55.85a.755.755 0 0 1-.596 1.021h-.17z" />
        <path d="M206.895 458.576c-.51 0-.85-.34-.85-.85v-240.83c0-.51.34-.85.85-.85s.85.34.85.85V457.64c0 .51-.34.935-.85.935zM189.711 161.895c-.34 0-.595-.17-.766-.51l-14.802-32.473c-.17-.425 0-.935.426-1.105.425-.17.936 0 1.106.425l14.802 32.558c.17.425 0 .935-.426 1.105h-.34z" />
        <path d="M157.895 217.151h-.17a.882.882 0 0 1-.68-1.02l17.013-87.814a.882.882 0 0 1 1.021-.68c.426.085.766.51.68 1.02l-17.013 87.814c-.085.425-.51.68-.85.68z" />
        <path d="M256.575 496.66H237.69c-.255 0-.51-.085-.68-.34l-62.526-76.338c-.085-.17-.17-.255-.17-.425l-17.27-203.171c0-.51.341-.85.766-.935.426-.085.851.34.936.765l17.269 202.916 62.1 75.828h18.46c.51 0 .85.34.85.85s-.425.85-.85.85z" />
        <path d="M206.895 217.746l-49.085-.595a.852.852 0 0 1-.85-.85c0-.425.425-.85.85-.85l49.085.595c.51 0 .85.425.85.85 0 .51-.34.85-.85.85z" />
        <path d="M157.895 217.151a.773.773 0 0 1-.595-.255l-42.534-42.59a.821.821 0 0 1 0-1.19c.34-.34.85-.34 1.19 0l42.535 42.59c.34.34.34.85 0 1.19a.92.92 0 0 1-.596.255zM256.575 217.746h-49.68c-.51 0-.85-.34-.85-.85s.34-.85.85-.85h49.68c.51 0 .85.34.85.85s-.425.85-.85.85zM174.91 129.422c-.171 0-.341-.085-.511-.17-.34-.255-.426-.85-.17-1.19L255.894 16.02c.256-.34.766-.425 1.191-.17.34.255.426.85.17 1.19L175.59 129.082c-.17.17-.426.34-.68.34zM261.85 495.98c-.17 0-.341-.085-.511-.17-.34-.255-.426-.85-.17-1.19l118.67-161.517c.256-.34.851-.425 1.191-.17.34.255.426.85.17 1.19L262.53 495.64c-.17.17-.426.34-.68.34z" />
        <path d="M380.52 334.463h-.085c-.426-.085-.766-.51-.766-.935l17.27-159.817c.084-.425.51-.765.935-.765.425.085.765.51.765.935l-17.268 159.817c0 .425-.426.765-.851.765z" />
        <path d="M397.874 174.562a.773.773 0 0 1-.596-.255L255.98 17.04c-.34-.34-.255-.85.086-1.19.34-.34.85-.255 1.19.085l141.3 157.266c.34.34.255.85-.086 1.19-.255.086-.425.17-.595.17z" />
        <path d="M323.439 161.895c-.34 0-.596-.17-.766-.51L255.81 16.87c-.17-.425 0-.935.426-1.105.425-.17.935 0 1.106.425l66.863 144.6c.17.425 0 .935-.425 1.105h-.34z" />
        <path d="M306.255 217.746H306c-.426-.17-.68-.595-.596-1.02l17.184-55.85c.17-.426.596-.68 1.021-.596.425.17.68.595.595 1.02l-17.183 55.851c-.085.425-.426.595-.766.595z" />
        <path d="M306.255 458.576c-.51 0-.85-.34-.85-.85v-240.83c0-.51.34-.85.85-.85s.85.34.85.85V457.64c0 .51-.425.935-.85.935zM323.439 161.895c-.085 0-.255 0-.34-.085-.426-.17-.596-.68-.426-1.105l14.802-32.558c.17-.425.68-.595 1.106-.425.425.17.596.68.425 1.105l-14.802 32.558c-.17.34-.425.51-.765.51z" />
        <path d="M355.255 217.151c-.426 0-.766-.255-.851-.68l-17.014-87.814c-.085-.425.17-.935.68-1.02.426-.085.936.17 1.021.68l17.014 87.814c.085.425-.17.935-.68 1.02h-.17z" />
        <path d="M275.46 496.66h-18.885c-.51 0-.85-.34-.85-.85s.34-.85.85-.85h18.46l62.1-75.828 17.269-202.916c0-.51.425-.85.936-.765.425 0 .85.425.765.935l-17.269 203.171a.65.65 0 0 1-.17.425l-62.525 76.338c-.17.255-.426.34-.68.34z" />
        <path d="M306.255 217.746a.852.852 0 0 1-.85-.85c0-.51.34-.85.85-.85l49.085-.595c.51-.085.85.34.85.85s-.34.85-.85.85l-49.085.595z" />
        <path d="M355.255 217.151a.773.773 0 0 1-.596-.255.821.821 0 0 1 0-1.19l42.534-42.59c.34-.34.851-.34 1.191 0 .34.34.34.85 0 1.19l-42.534 42.59a.773.773 0 0 1-.595.255zM306.255 217.746h-49.68c-.51 0-.85-.34-.85-.85s.34-.85.85-.85h49.68c.51 0 .85.34.85.85s-.425.85-.85.85zM338.24 129.422c-.254 0-.51-.085-.68-.34L255.894 17.04c-.255-.34-.17-.935.17-1.19.34-.255.936-.17 1.192.17l81.665 112.042c.256.34.17.935-.17 1.19-.17.085-.34.17-.51.17z" />
      </g>
    </svg>
  </div>
);

export default LogoSmall;
