import React from 'react';
import styles from '../css/components/Logo.module.css';

const Logo = props => (
  <div className={styles.logo}>
    <svg viewBox="0 0 512 512" {...props}>
      <path d="M185.3 495.98c-.255 0-.51-.085-.68-.34L65.95 334.123c-.256-.34-.17-.935.17-1.19.34-.255.935-.17 1.19.17L185.982 494.62c.255.34.17.935-.17 1.19-.17.085-.34.17-.51.17z" />
      <path d="M66.63 334.463c-.425 0-.766-.34-.85-.765L48.51 173.881c-.085-.425.256-.85.766-.935.51-.085.85.255.936.765L67.48 333.528c.085.425-.255.85-.766.935h-.085z" />
      <path d="M49.276 174.562a.92.92 0 0 1-.595-.255.91.91 0 0 1-.086-1.19l141.3-157.267a.91.91 0 0 1 1.19-.085c.34.34.34.85.085 1.19L49.872 174.222c-.086.255-.34.34-.596.34z" />
      <path d="M123.711 161.895c-.085 0-.255 0-.34-.085-.426-.17-.596-.68-.426-1.105l66.864-144.6c.17-.425.68-.595 1.106-.425.426.17.596.68.426 1.105l-66.864 144.6c-.17.34-.426.51-.766.51z" />
      <path d="M140.895 217.746c-.34 0-.68-.255-.85-.595l-17.185-55.85a.755.755 0 0 1 .596-1.02.755.755 0 0 1 1.02.594l17.185 55.85a.755.755 0 0 1-.596 1.021h-.17z" />
      <path d="M140.895 458.576c-.51 0-.85-.34-.85-.85v-240.83c0-.51.34-.85.85-.85s.85.34.85.85V457.64c0 .51-.34.935-.85.935zm-17.184-296.681c-.34 0-.595-.17-.766-.51l-14.802-32.473c-.17-.425 0-.935.426-1.105.425-.17.936 0 1.106.425l14.802 32.558c.17.425 0 .935-.426 1.105h-.34z" />
      <path d="M91.895 217.151h-.17a.882.882 0 0 1-.68-1.02l17.013-87.814a.882.882 0 0 1 1.021-.68.88.88 0 0 1 .68 1.02L92.747 216.47c-.085.425-.51.68-.85.68z" />
      <path d="M190.575 496.66H171.69c-.255 0-.51-.085-.68-.34l-62.526-76.338c-.085-.17-.17-.255-.17-.425l-17.27-203.171c0-.51.341-.85.766-.935.426-.085.851.34.936.765l17.269 202.916 62.1 75.828h18.46c.51 0 .85.34.85.85s-.425.85-.85.85z" />
      <path d="M140.895 217.746l-49.085-.595a.852.852 0 0 1-.85-.85c0-.425.425-.85.85-.85l49.085.595c.51 0 .85.425.85.85 0 .51-.34.85-.85.85z" />
      <path d="M91.895 217.151a.773.773 0 0 1-.595-.255l-42.534-42.59a.821.821 0 0 1 0-1.19c.34-.34.85-.34 1.19 0l42.535 42.59c.34.34.34.85 0 1.19a.92.92 0 0 1-.596.255zm98.68.595h-49.68c-.51 0-.85-.34-.85-.85s.34-.85.85-.85h49.68c.51 0 .85.34.85.85s-.425.85-.85.85zm-81.665-88.324c-.171 0-.341-.085-.511-.17-.34-.255-.426-.85-.17-1.19L189.894 16.02c.256-.34.766-.425 1.191-.17.34.255.426.85.17 1.19L109.59 129.082c-.17.17-.426.34-.68.34zm86.94 366.558c-.17 0-.341-.085-.511-.17-.34-.255-.426-.85-.17-1.19l118.67-161.517c.256-.34.851-.425 1.191-.17.34.255.426.85.17 1.19L196.53 495.64c-.17.17-.426.34-.68.34z" />
      <path d="M314.52 334.463h-.085c-.426-.085-.766-.51-.766-.935l17.27-159.817c.084-.425.51-.765.935-.765.425.085.765.51.765.935l-17.268 159.817c0 .425-.426.765-.851.765z" />
      <path d="M331.874 174.562a.773.773 0 0 1-.596-.255L189.98 17.04c-.34-.34-.255-.85.086-1.19.34-.34.85-.255 1.19.085l141.3 157.266c.34.34.255.85-.086 1.19-.255.086-.425.17-.595.17z" />
      <path d="M257.439 161.895c-.34 0-.596-.17-.766-.51L189.81 16.87c-.17-.425 0-.935.426-1.105.425-.17.935 0 1.106.425l66.863 144.6c.17.425 0 .935-.425 1.105h-.34z" />
      <path d="M240.255 217.746H240c-.426-.17-.68-.595-.596-1.02l17.184-55.85c.17-.426.596-.68 1.021-.596.425.17.68.595.595 1.02l-17.183 55.851c-.085.425-.426.595-.766.595z" />
      <path d="M240.255 458.576c-.51 0-.85-.34-.85-.85v-240.83c0-.51.34-.85.85-.85s.85.34.85.85V457.64c0 .51-.425.935-.85.935zm17.184-296.681c-.085 0-.255 0-.34-.085-.426-.17-.596-.68-.426-1.105l14.802-32.558c.17-.425.68-.595 1.106-.425.425.17.596.68.425 1.105l-14.802 32.558c-.17.34-.425.51-.765.51z" />
      <path d="M289.255 217.151c-.426 0-.766-.255-.851-.68l-17.014-87.814c-.085-.425.17-.935.68-1.02.426-.085.936.17 1.021.68l17.014 87.814c.085.425-.17.935-.68 1.02h-.17z" />
      <path d="M209.46 496.66h-18.885c-.51 0-.85-.34-.85-.85s.34-.85.85-.85h18.46l62.1-75.828 17.269-202.916c0-.51.425-.85.936-.765.425 0 .85.425.765.935l-17.269 203.171a.65.65 0 0 1-.17.425l-62.525 76.338c-.17.255-.426.34-.68.34z" />
      <path d="M240.255 217.746a.852.852 0 0 1-.85-.85c0-.51.34-.85.85-.85l49.085-.595c.51-.085.85.34.85.85s-.34.85-.85.85l-49.085.595z" />
      <path d="M289.255 217.151a.773.773 0 0 1-.596-.255.821.821 0 0 1 0-1.19l42.534-42.59a.823.823 0 0 1 1.191 0c.34.34.34.85 0 1.19l-42.534 42.59a.773.773 0 0 1-.595.255zm-49 .595h-49.68c-.51 0-.85-.34-.85-.85s.34-.85.85-.85h49.68c.51 0 .85.34.85.85s-.425.85-.85.85zm31.985-88.324c-.254 0-.51-.085-.68-.34L189.894 17.04c-.255-.34-.17-.935.17-1.19.34-.255.936-.17 1.192.17l81.665 112.042c.256.34.17.935-.17 1.19-.17.085-.34.17-.51.17zm131.942 144.43l-.17 2.465-.17.085c-2.467.935-3.913 2.72-4.509 5.27-.34 1.446-.255 3.656 0 6.546.426 4.846 1.191 12.157 2.212 21.933.425 3.485.34 6.12-.085 7.82-.766 3.06-2.807 5.271-6.04 6.546l-.936.34.17-2.55c2.382-.935 3.829-2.72 4.424-5.27.34-1.446.34-3.656 0-6.546-.425-4.846-1.19-12.157-2.212-21.933-.425-3.485-.34-6.035.085-7.82.766-3.06 2.723-5.271 5.955-6.546l1.276-.34z" />
      <path d="M393.719 326.217l.255-4.42.51-.17c2.127-.85 3.403-2.38 3.914-4.676.255-1.36.255-3.485 0-6.29-.426-4.59-1.106-11.562-2.212-21.933-.426-3.57-.34-6.29.085-8.075.85-3.4 2.977-5.781 6.465-7.141l1.872-.765.425.935v.255l-.17 3.06-.68.255c-2.128.85-3.489 2.38-3.999 4.676-.255 1.36-.255 3.485.085 6.29.425 4.506 1.106 11.222 2.212 21.933.425 3.57.425 6.29-.085 8.16-.85 3.316-3.063 5.781-6.55 7.141l-2.127.765zm1.871-3.315v.765c2.808-1.19 4.509-3.06 5.19-5.78.425-1.7.425-4.166 0-7.566a3363.207 3363.207 0 0 1-2.212-21.933c-.34-2.975-.34-5.27 0-6.8.595-2.72 2.211-4.676 4.678-5.78v-.681c-2.892 1.19-4.678 3.06-5.359 5.866-.425 1.615-.425 4.165-.085 7.48 1.106 10.371 1.786 17.342 2.212 21.933.34 2.975.34 5.27 0 6.8-.51 2.636-2.042 4.59-4.424 5.696zm67.375-49.05l-.085 2.465-.17.085c-2.468.935-3.914 2.72-4.51 5.27-.34 1.446-.34 3.656 0 6.546.426 4.846 1.192 12.157 2.213 21.933.425 3.485.34 6.12-.086 7.82-.765 3.06-2.807 5.271-6.04 6.546l-.935.34.17-2.55c2.382-.935 3.828-2.72 4.424-5.27.34-1.446.34-3.656 0-6.546-.426-4.846-1.191-12.157-2.212-21.933-.426-3.485-.34-6.035.085-7.82.765-3.06 2.722-5.271 5.955-6.546l1.19-.34z" />
      <path d="M452.586 326.217l.255-4.42.51-.17c2.128-.85 3.404-2.38 3.914-4.676.34-1.36.255-3.485 0-6.29-.425-4.506-1.106-11.221-2.212-21.933-.425-3.485-.34-6.205.085-8.075.851-3.4 2.978-5.781 6.465-7.141l1.787-.68.425.935v.255l-.17 3.06-.68.255c-2.127.85-3.488 2.38-3.999 4.676-.255 1.36-.255 3.485.085 6.29.426 4.42 1.021 10.966 2.212 21.933.426 3.57.426 6.29-.085 8.16-.85 3.316-3.062 5.781-6.55 7.141l-2.042.68zm1.872-3.315v.765c2.807-1.19 4.508-3.06 5.189-5.78.425-1.7.425-4.251 0-7.566-1.191-10.967-1.787-17.597-2.212-21.933-.34-3.06-.34-5.27 0-6.8.596-2.72 2.127-4.676 4.679-5.78v-.681c-2.892 1.19-4.679 3.06-5.36 5.866-.425 1.7-.425 4.165-.084 7.48a3363.208 3363.208 0 0 1 2.211 21.933c.34 3.06.34 5.27 0 6.8-.595 2.72-2.041 4.59-4.423 5.696zM429.193 273.767h2.382v51.175h-2.382z" />
      <path d="M432.425 325.792h-4.083v-52.875h4.083v52.875zm-2.382-1.7h.68v-49.475h-.68v49.475z" />
      <g>
        <path d="M373.472 273.767h2.382v51.175h-2.382z" />
        <path d="M376.79 325.792h-4.083v-52.875h4.083v52.875zm-2.467-1.7h.68v-49.475h-.68v49.475z" />
      </g>
    </svg>
  </div>
);

export default Logo;
