import React from 'react';
import { Link } from 'gatsby';

import LogoSmall from './LogoSmall';
import { Facebook, Instagram, Email } from './Social';

// CSS
import css from '../css/components/Footer.module.css';

export default ({ children }) => (
  <footer className={css.footer}>
    <div className={css.social}>
      <Instagram />
      <Facebook />
      <Email />
    </div>
    <div className={css.logo}>
      <Link to="/">
        <LogoSmall />
      </Link>
    </div>
    <div className={css.colophon}>
      <a href="https://iljapanic.com" target="_blank" rel="noopener noreferrer">
        Webdesign by Ilja Panic
      </a>
    </div>
  </footer>
);
