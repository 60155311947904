import React from 'react';
import MessengerCustomerChat from 'react-messenger-customer-chat';

class Messenger extends React.Component {
  render() {
    const messengerGreeting = 'Ráda zodpovím vaše dotazy ohledně kamenů a mých produktů';

    if (process.env.NODE_ENV === 'production') {
      return (
        <MessengerCustomerChat
          pageId={process.env.FACEBOOK_PAGE_ID}
          appId={process.env.FACEBOOK_APP_ID}
          minimized={true}
          themeColor="#87a69f"
          greetingDialogDisplay="hide"
          loggedInGreeting={messengerGreeting}
          loggedOutGreeting={messengerGreeting}
        />
      );
    } else {
      return null;
    }
  }
}

export default Messenger;
