import React from 'react';

// components
import Head from './Head';
import Header from './Header';
import Footer from './Footer';
import Messenger from './Messenger';

// global CSS
import '../css/utils/normalize.css';
import '../css/utils/base.css';
import '../css/utils/helpers.css';

// component CSS
import styles from '../css/components/Container.module.css';

class Container extends React.Component {
  render() {
    return (
      <div className={styles.container}>
        <Head title={this.props.title} permalink={this.props.permalink} />
        <Header title={this.props.title} />
        {this.props.children}
        <Footer />
        <Messenger />
      </div>
    );
  }
}

export default Container;
