import React from 'react';
import { Helmet } from 'react-helmet';
import { StaticQuery, graphql } from 'gatsby';

// images
import favicon from '../images/favicon.png';
import cover from '../images/cover.jpg';

class Head extends React.Component {
  render() {
    const localMeta = this.props.data.site.siteMetadata;
    const datoMeta = this.props.data.datoCmsSite.globalSeo;

    const siteUrl = localMeta.siteUrl;
    const facebookPageUrl = localMeta.facebook;
    const facebookAppId = localMeta.facebookAppId;
    const siteName = datoMeta.siteName;

    var title = siteName;
    var description = datoMeta.fallbackSeo.description;
    var thumbnailUrl = cover;
    var permalink = siteUrl;

    if (this.props.title) {
      title = this.props.title + ' ☉ ' + siteName;
    }

    if (this.props.description) {
      description = this.props.description;
    }

    if (this.props.thumbnail) {
      thumbnailUrl = this.props.thumbnail;
    }

    if (this.props.permalink) {
      const pageLocation = this.props.permalink;
      const pagePermalink = siteUrl + pageLocation;
      permalink = pagePermalink;
    }

    return (
      <Helmet>
        <meta charSet="utf-8" />
        <title>{title}</title>
        <meta name="description" content={description} />
        <link rel="shortcut icon" type="image/png" href={favicon} />
        <link rel="canonical" href={permalink} />

        <meta name="og:type" content="website" />
        <meta name="og:site_name" content={siteName} />
        <meta property="og:title" content={title} />
        <meta property="og:url" content={permalink} />
        <meta property="og:description" content={description} />
        <meta property="og:image" content={thumbnailUrl} />
        <meta property="fb:app_id" content={facebookAppId} />
        <meta property="article:author" content={facebookPageUrl} />
      </Helmet>
    );
  }
}

export default props => (
  <StaticQuery query={headQuery} render={data => <Head data={data} {...props} />} />
);

const headQuery = graphql`
  query headQuery {
    site {
      siteMetadata {
        siteUrl
        facebook
        facebookAppId
      }
    }
    datoCmsSite(locale: { eq: "cs" }) {
      globalSeo {
        siteName
        fallbackSeo {
          description
        }
      }
    }
  }
`;
