import React from 'react';
import { Link, StaticQuery, graphql } from 'gatsby';

import Logo from './Logo';
import Nav from './Nav';
import Social from './Social';

import css from '../css/components/Header.module.css';

class Header extends React.Component {
  render() {
    const siteName = this.props.data.datoCmsSite.globalSeo.siteName;
    var title = siteName;

    if (this.props.title) {
      title = this.props.title;
    }

    return (
      <header className={css.header}>
        <div className={css.logo}>
          <Link to="/">
            <span className="sr-only">{siteName}</span>
            <Logo />
          </Link>
        </div>

        <div className={css.nav}>
          <Nav />
        </div>

        <div className={css.social}>
          <Social />
        </div>
      </header>
    );
  }
}

export default props => (
  <StaticQuery query={headerQuery} render={data => <Header data={data} {...props} />} />
);

const headerQuery = graphql`
  query headerQuery {
    datoCmsSite {
      globalSeo {
        siteName
      }
    }
  }
`;
